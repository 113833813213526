import { SM, MD, LG, XL } from '@prolific-oss/design-system/dist/tokens.js'
import type { Alpha2Code } from 'i18n-iso-countries'
import {
  REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION,
  type RepSampleType,
} from '@/api/study_type'

export * from './api-docs-links'
export * from './survey-builder'
export * from './submissions-config'
export * from './task-builder'
export * from './filters'

export const CARD_TYPES = {
  STUDY: 'STUDY',
  QUESTIONS: 'QUESTIONS',
  VERIFICATION: 'VERIFICATION',
  WELCOME: 'WELCOME',
}

export const QUESTIONS_TYPES = {
  ONBOARD: 'onboarding',
  DYNAMIC: 'dynamic',
}

export const STUDY_TAG_TYPE = {
  REWARD: 'reward',
  FIXED_PRICE_REWARD: 'fixedPriceReward',
  COMPLETION_TIME: 'completionTime',
  REWARD_AND_REWARD_PER_HOUR: 'rewardAndRewardPerHour',
  PLACES: 'places',
  QUESTIONS_COUNT: 'questionsCount',
  MULTI_SUBMISSION: 'multiSubmission',
  PREVIOUS_SUBMISSION_COUNT: 'previousSubmissionCount',
  THROUGHPUT_OPTIMISATION: 'throughputOptimisation',
  STUDY_LABEL: 'studyLabel',
  CONTENT_WARNINGS: 'contentWarnings',
  PII: 'piiWarnings',
  MAX_ALLOWED_TIME: 'maxAllowedTime',
  CUSTOM_SCREENING: 'customScreening',
  DYNAMIC_PAYMENT: 'dynamicPayment',
  DYNAMIC_PAYMENT_TIME: 'dynamicPaymentTime',
}

export const COUNTRY_MAPPING = {
  uk: 'United Kingdom',
  usa: 'United States',
}

export const REP_STUDY_TAGS = {
  SEX: 'rep_sample_sex',
  AGE: 'rep_sample_age',
  ETHNICITY: 'rep_sample_ethnicity',
  US_POLITICAL_AFFILIATION: 'us_rep_sample_political_affiliation',
}

/**
 * We define the study tags we want to use for stratum labels in this order
 * as there are other rep study tags we don't want to use
 * and sometimes the keys are not returned in the same order
 */
export const REP_STUDY_STRATUM_LABEL_TAGS = (studyType: RepSampleType) => [
  REP_STUDY_TAGS.AGE,
  ...(studyType === REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION
    ? []
    : [REP_STUDY_TAGS.ETHNICITY]),
  REP_STUDY_TAGS.SEX,
  ...(studyType === REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION
    ? [REP_STUDY_TAGS.US_POLITICAL_AFFILIATION]
    : []),
]

export const PIVOT_TAG = REP_STUDY_TAGS.AGE
export const SORT_TAG = REP_STUDY_TAGS.SEX

export const REP_STUDY_TAGS_LABELS = {
  rep_sample_sex: 'sex',
  rep_sample_age: 'age',
  rep_sample_ethnicity: 'ethnicity',
  us_rep_sample_political_affiliation: 'us_political_affiliation',
}

export const DEFAULT_LIST_PAGE_SIZE = 20

export const DEFAULT_HOME_CURRENCY = 'GBP'
export const MIN_CASHOUT_BALANCE_PENCE = 500

export const MIN_REWARD_PER_HOUR_GBP = 600
export const MIN_REWARD_PER_HOUR_USD = 800

export const RECOMMENDED_REWARD_PER_HOUR_GBP = 900
export const RECOMMENDED_REWARD_PER_HOUR_USD = 1200

export const MIN_REWARD_PER_MINUTE_GBP = 10
export const MIN_REWARD_PER_MINUTE_USD = 14

// Eligible participant counts <= this number will be obfuscated
// for privacy reasons.
export const ELIGIBLE_PARTICIPANT_COUNT_OBFUSCATION_LIMIT = 25

export const BLOG_BASE_URL = 'https://prolific.com/blog'

const DS_BREAKPOINTS = { SM, MD, LG, XL } as const
export const BREAKPOINTS = Object.fromEntries(
  Object.entries(DS_BREAKPOINTS).map(([key, value]) => [
    key,
    Number(value.replace('px', '')),
  ])
) as Record<keyof typeof DS_BREAKPOINTS, number>

export const US_SANCTIONS_COUNTRIES_BLOCKLIST: Partial<
  Record<Alpha2Code, string>
> = {
  CU: 'Cuba',
  IR: 'Iran, Islamic Republic of',
  SY: 'Syrian Arab Republic',
  // This actually isn't part of the countries the api is returning, but blocking anyway for future
  KP: 'North Korea',
}

export const US_SANCTIONS_UKRAINE: Alpha2Code = 'UA'

// Hardcoded on the FE for validation but not required in the BE
export const US_SANCTIONS_UKRAINIAN_REGIONS: string[] = [
  'Chernivtsi',
  'Chernihiv',
  'Cherkasy',
  'Crimea',
  'Donetsk',
  'Dnipropetrovsk',
  'Ivano-Frankivsk',
  'Khmelnytskyi',
  'Kirovohrad',
  'Kharkiv',
  'Kherson',
  'Kyiv',
  'Luhansk',
  'Lviv',
  'Mykolaiv',
  'Odesa',
  'Poltava',
  'Rivne',
  'Sevastopol',
  'Sumy',
  'Ternopil',
  'Vinnytsia',
  'Volyn',
  'Zakarpattia',
  'Zaporizhzhia',
  'Zhytomyr',
]

export const US_SANCTIONS_UKRAINIAN_REGIONS_BLOCKLIST: string[] = [
  'Crimea',
  'Donetsk',
  'Luhansk',
]

// NP: Nepal, NG: Nigeria, PK: Pakistan, TZ: Tanzania, UG: Uganda, ZM: Zambia
export const GLOBAL_REP_SAMPLE_COUNTRIES: Alpha2Code[] = [
  'NP',
  'NG',
  'PK',
  'TZ',
  'UG',
  'ZM',
]

// AU: Australia, AT: Austria, BE: Belgium, CA: Canada, CL: Chile, CO: Colombia, CR: Costa Rica, CZ: Czech Republic, DK: Denmark, EE: Estonia, FI: Finland, FR: France, DE: Germany, GR: Greece, HU: Hungary, IS: Iceland, IE: Ireland, IL: Israel, IT: Italy, JP: Japan, LV: Latvia, LT: Lithuania, LU: Luxembourg, MX: Mexico, NL: Netherlands, NZ: New Zealand, NO: Norway, PL: Poland, PT: Portugal, SK: Slovakia, SI: Slovenia, ES: Spain, SE: Sweden, CH: Switzerland, GB: United Kingdom, US: United States, AL: Albania, DZ: Algeria, AD: Andorra, AG: Antigua and Barbuda, AR: Argentina, BS: Bahamas, BH: Bahrain, BB: Barbados, BZ: Belize, BM: Bermuda, BA: Bosnia and Herzegovina, BW: Botswana, BR: Brazil, BG: Bulgaria, KY: Cayman Islands, CN: China, HR: Croatia, CY: Cyprus, DM: Dominica, DO: Dominican Republic, EC: Ecuador, EG: Egypt, SV: El Salvador, FO: Faroe Islands, FJ: Fiji, GF: French Guiana, PF: French Polynesia, GE: Georgia, GI: Gibraltar, GL: Greenland, GD: Grenada, GP: Guadeloupe, GT: Guatemala, HN: Honduras, IN: India, ID: Indonesia, JM: Jamaica, JO: Jordan, KZ: Kazakhstan, KE: Kenya, KW: Kuwait, LS: Lesotho, LI: Liechtenstein, MW: Malawi, MY: Malaysia, MT: Malta, MQ: Martinique, MU: Mauritius, MD: Moldova, MA: Morocco, MZ: Mozambique, NC: New Caledonia, NI: Nicaragua, OM: Oman, PW: Palau, PA: Panama, PE: Peru, PH: Philippines, QA: Qatar, RE: Réunion, RO: Romania, RU: Russia, KN: Saint Kitts and Nevis, LC: Saint Lucia, SM: San Marino, SA: Saudi Arabia, SN: Senegal, SC: Seychelles, SG: Singapore, ZA: South Africa, KR: South Korea, TW: Taiwan, TH: Thailand, TT: Trinidad and Tobago, TC: Turks and Caicos Islands, AE: United Arab Emirates, UY: Uruguay, VE: Venezuela, VN: Vietnam
export const SUPPORTED_HYPERWALLET_COUNTRIES: Alpha2Code[] = [
  'AU',
  'AT',
  'BE',
  'CA',
  'CL',
  'CO',
  'CR',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IS',
  'IE',
  'IL',
  'IT',
  'JP',
  'LV',
  'LT',
  'LU',
  'MX',
  'NL',
  'NZ',
  'NO',
  'PL',
  'PT',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'GB',
  'US',
  'AL',
  'DZ',
  'AD',
  'AG',
  'AR',
  'BS',
  'BH',
  'BB',
  'BZ',
  'BM',
  'BA',
  'BW',
  'BR',
  'BG',
  'KY',
  'CN',
  'HR',
  'CY',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'FO',
  'FJ',
  'GF',
  'PF',
  'GE',
  'GI',
  'GL',
  'GD',
  'GP',
  'GT',
  'HN',
  'IN',
  'ID',
  'JM',
  'JO',
  'KZ',
  'KE',
  'KW',
  'LS',
  'LI',
  'MW',
  'MY',
  'MT',
  'MQ',
  'MU',
  'MD',
  'MA',
  'MZ',
  'NC',
  'NI',
  'OM',
  'PW',
  'PA',
  'PE',
  'PH',
  'QA',
  'RE',
  'RO',
  'RU',
  'KN',
  'LC',
  'SM',
  'SA',
  'SN',
  'SC',
  'SG',
  'ZA',
  'KR',
  'TW',
  'TH',
  'TT',
  'TC',
  'AE',
  'UY',
  'VE',
  'VN',
]

export const DYNAMIC_PAYMENT_LEARN_MORE_LINK_URL =
  'https://participant-help.prolific.com/en/article/2252e5'

export const RESEARCHER_REGISTER_URL =
  'https://app.prolific.com/register/researcher'

export const WEBSITE_URL = 'https://www.prolific.com'

export const CUSTOM_QUOTA_PERCENTAGE_DECIMAL_PLACES = 1

export const SUBMISSION_SORTING_MAP: Record<string, string> = {
  STARTED_DATE_NEWEST: '-started_at',
  STARTED_DATE_OLDEST: 'started_at',
  REWARD_HIGHEST: '-submission_reward',
  REWARD_LOWEST: 'submission_reward',
  STUDY_NAME_ASCENDING: 'study_name',
  STUDY_NAME_DESCENDING: '-study_name',
}

export const DEFAULT_SUBMISSION_SORTING =
  SUBMISSION_SORTING_MAP['STARTED_DATE_NEWEST']

export const SUBMISSION_STATUS_OPTIONS = [
  'all',
  'approved',
  'awaiting_review',
  'rejected',
  'returned',
  'timeout',
  // 'screened_out', - Remove this option for now
]

export const DEFAULT_SUBMISSION_STATUS = 'all'

export const RESEARCHER_TYPES = [
  'ACADEMIA',
  'BUSINESS',
  'NONPROFIT',
  'OTHER',
] as const

export const FEDERATED_IDENTITY_SERVICE_PROVIDERS = {
  GOOGLE: 'google-cloud-identity',
}

export const DATA_COLLECTION_EXTERNAL = 'external'
export const DATA_COLLECTION_SURVEY_BUILDER = 'survey_builder'
export const DATA_COLLECTION_TASKFLOW = 'taskflow'
export const DATA_COLLECTION_METHODS = [
  DATA_COLLECTION_EXTERNAL,
  DATA_COLLECTION_SURVEY_BUILDER,
  DATA_COLLECTION_TASKFLOW,
] as const
