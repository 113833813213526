import type { StudyType } from '@/api/types'

export const SINGLE = 'SINGLE'
export const QUOTA = 'QUOTA'

// rep sample types
export type RepSampleType = Extract<
  StudyType,
  | 'UK_REP_SAMPLE'
  | 'US_REP_SAMPLE'
  | 'REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION'
  | 'REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION'
  | 'REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY'
  | 'REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY'
>

type RepSampleLimits = {
  [K in RepSampleType]: {
    min: number
    max: number
  }
}

export const UK_REP_SAMPLE = 'UK_REP_SAMPLE'
export const US_REP_SAMPLE = 'US_REP_SAMPLE'
export const REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION =
  'REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION'
export const REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY =
  'REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY'
export const REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION =
  'REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION'
export const REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY =
  'REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY'

export const REP_SAMPLE_LIMITS: RepSampleLimits = {
  [UK_REP_SAMPLE]: {
    min: 300,
    max: 3800,
  },
  [US_REP_SAMPLE]: {
    min: 300,
    max: 2500,
  },
  [REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION]: {
    min: 300,
    max: 5600,
  },
  [REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY]: {
    min: 300,
    max: 800,
  },
  [REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION]: {
    min: 300,
    max: 1200,
  },
  [REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY]: {
    min: 300,
    max: 500,
  },
}

export const UK_REP_SAMPLE_TYPES = [
  UK_REP_SAMPLE,
  REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION,
  REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY,
] satisfies RepSampleType[]

export const US_REP_SAMPLE_TYPES = [
  US_REP_SAMPLE,
  REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION,
  REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY,
] satisfies RepSampleType[]

export const ALL_REP_SAMPLE_TYPES = [
  ...UK_REP_SAMPLE_TYPES,
  ...US_REP_SAMPLE_TYPES,
] satisfies RepSampleType[]

// helpers
function isStudyType(type: StudyType, expectedType: StudyType | StudyType[]) {
  if (Array.isArray(expectedType)) {
    return expectedType.some(expected => type === expected)
  }
  return type === expectedType
}

export function isSingle(type: StudyType) {
  return isStudyType(type, SINGLE)
}

export function isRepSample(type: StudyType): type is RepSampleType {
  return isStudyType(type, ALL_REP_SAMPLE_TYPES)
}

export function isUkRepSample(type: StudyType) {
  return isStudyType(type, UK_REP_SAMPLE_TYPES)
}

export function isUsRepSample(type: StudyType) {
  return isStudyType(type, US_REP_SAMPLE_TYPES)
}

export function isQuota(type: StudyType) {
  return isStudyType(type, QUOTA)
}
